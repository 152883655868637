import React, { useMemo } from 'react';
import { Input, InputOnChangeData, List, ListItem, Dropdown, DropdownOnSearchChangeData } from 'semantic-ui-react';
import { Logger, LoggingService } from '../../services/LoggingService';
import { ICustomAttributeInputConfig } from '../../models/contactInfoDisplaySets';
import { ConfigurationService } from '../../services/ConfigurationService';
import { ICustomAttributesState } from './ContactInfo';
import fuzzysort from 'fuzzysort';


export const CustomAttributeInput = (
  inputConfig: ICustomAttributeInputConfig,
  customAttributes: ICustomAttributesState,
  setCustomAttributes: React.Dispatch<
    React.SetStateAction<ICustomAttributesState>
  >,
  config: ConfigurationService,
  agentLang: string,
  contactId: string
) => {
  const logger: Logger = new LoggingService().getLogger('CustomAttributeInput');

  if (!inputConfig.label || !inputConfig.attributeKey) {
    logger.error('Missing label or attributeKey in inputConfig, skipping component', {...inputConfig});
    return null;
  }

  const attributeData = {
    value: customAttributes[contactId]?.[inputConfig.attributeKey]?.value! ?? '',
    inputValue: '',
    filteredOptions: inputConfig.values || []
  };

  const getPlaceholder = () => {
    return config.translate(inputConfig.placeholder || 'Please enter text here', agentLang);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData | DropdownOnSearchChangeData) => {
    const newValue = data.value as string;
    let newFilteredOptions = attributeData.filteredOptions;

    if (inputConfig.values) {
      const results = fuzzysort.go(data.searchQuery, inputConfig.values);
      newFilteredOptions = results.map(result => result.target);
    }

    if (newValue === '' || data.searchQuery == '') {
      newFilteredOptions = inputConfig.values;
    }

    updateCustomAttribute(newValue, newValue, newFilteredOptions);
  };

  const handleSelect = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    const selectedValue = data.value;
    updateCustomAttribute(selectedValue, selectedValue, inputConfig.values);
  };

  const updateCustomAttribute = (value: string, inputValue: string, filteredOptions: string[]) => {
    const updates = {
      [contactId]: {
        ...customAttributes[contactId],
        [inputConfig.attributeKey]: {
          value,
          inputValue,
          filteredOptions
        }
      }
    };
    setCustomAttributes(prev => ({...prev, ...updates}));
  };

  const inputElement = () => {
    if (inputConfig.values && inputConfig.values.length > 0) {
      return (
        <Dropdown
          search
          selection
          options={attributeData.filteredOptions.map(option => ({ key: option, text: option, value: option }))}
          value={attributeData.value}
          onChange={handleSelect}
          onSearchChange={(e, data) => handleChange(e as React.ChangeEvent<HTMLInputElement>, data)}
          placeholder={getPlaceholder()}
          fluid
        />
      );
    } else {
      return (
        <Input
          placeholder={getPlaceholder()}
          style={{width: '100%'}}
          value={attributeData.inputValue}
          name={inputConfig.attributeKey}
          onChange={handleChange}
        />
      );
    }
  }

  return (
    <ListItem key={inputConfig.attributeKey}>
      <List.Header>
        <strong>{config.translate(inputConfig.label, agentLang) || inputConfig.label}</strong>
      </List.Header>
      <List.Description>
        {inputElement()}
      </List.Description>
    </ListItem>
  );
};